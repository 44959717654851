export default [
    {
        id:1,
        name:'Fresas',
        description: 'Fresas',
        categories: ['hogar', 'alimentos'],
        keys: ['fresas', 'moras', 'frutas', 'frutos'],
        images: [
            require('@/assets/images/products/1/1.png')
        ],
        seller:{
            name: 'Alpina',
            description: 'Vendedor Alpina',
            contact:{}
        },
        discount: '10%',
        beforeValue: 16.00,
        currentValue: 18.00,
        sales: 10,
    },

    {
        id:2,
        name:'Fresas',
        description: 'Fresas',
        categories: ['hogar', 'alimentos'],
        keys: ['fresas', 'moras', 'frutas', 'frutos'],
        images: [
            require('@/assets/images/products/2/2.png')
        ],
        seller:{
            name: 'Alpina',
            description: 'Vendedor Alpina',
            contact:{}
        },
        value: 18.00,
        sales: 10,
        promotion: {
            discount: '10%',
            value: 16.00
        }
    },

    {
        id:3,
        name:'Fresas',
        description: 'Fresas',
        categories: ['hogar', 'alimentos'],
        keys: ['fresas', 'moras', 'frutas', 'frutos'],
        images: [
            require('@/assets/images/products/3/3.png')
        ],
        seller:{
            name: 'Alpina',
            description: 'Vendedor Alpina',
            contact:{}
        },
        value: 18.00,
        sales: 10,
        promotion: {
            discount: '10%',
            value: 16.00
        }
    },

    {
        id:4,
        name:'Fresas',
        description: 'Fresas',
        categories: ['hogar', 'alimentos'],
        keys: ['fresas', 'moras', 'frutas', 'frutos'],
        images: [
            require('@/assets/images/products/4/4.png')
        ],
        seller:{
            name: 'Alpina',
            description: 'Vendedor Alpina',
            contact:{}
        },
        value: 18.00,
        sales: 10,
        promotion: {
            discount: '10%',
            value: 16.00
        }
    },

    {
        id:5,
        name:'Fresas',
        description: 'Fresas',
        categories: ['hogar', 'alimentos'],
        keys: ['fresas', 'moras', 'frutas', 'frutos'],
        images: [
            require('@/assets/images/products/5/5.png')
        ],
        seller:{
            name: 'Alpina',
            description: 'Vendedor Alpina',
            contact:{}
        },
        value: 18.00,
        sales: 10,
        promotion: {
            discount: '10%',
            value: 16.00
        }
    },


    {
        id:6,
        name:'Fresas',
        description: 'Fresas',
        categories: ['hogar', 'alimentos'],
        keys: ['fresas', 'moras', 'frutas', 'frutos'],
        images: [
            require('@/assets/images/products/6/6.png')
        ],
        seller:{
            name: 'Alpina',
            description: 'Vendedor Alpina',
            contact:{}
        },
        value: 18.00,
        sales: 10,
        promotion: {
            discount: '10%',
            value: 16.00
        }
    },


    {
        id:7,
        name:'Fresas',
        description: 'Fresas',
        categories: ['hogar', 'alimentos'],
        keys: ['fresas', 'moras', 'frutas', 'frutos'],
        images: [
            require('@/assets/images/products/7/7.png')
        ],
        seller:{
            name: 'Alpina',
            description: 'Vendedor Alpina',
            contact:{}
        },
        value: 18.00,
        sales: 10,
        promotion: {
            discount: '10%',
            value: 16.00
        }
    },


    {
        id:8,
        name:'Fresas',
        description: 'Fresas',
        categories: ['hogar', 'alimentos'],
        keys: ['fresas', 'moras', 'frutas', 'frutos'],
        images: [
            require('@/assets/images/products/8/8.png')
        ],
        seller:{
            name: 'Alpina',
            description: 'Vendedor Alpina',
            contact:{}
        },
        value: 18.00,
        sales: 10,
        promotion: {
            discount: '10%',
            value: 16.00
        }
    },


    {
        id:9,
        name:'Fresas',
        description: 'Fresas',
        categories: ['hogar', 'alimentos'],
        keys: ['fresas', 'moras', 'frutas', 'frutos'],
        images: [
            require('@/assets/images/products/9/9.png')
        ],
        seller:{
            name: 'Alpina',
            description: 'Vendedor Alpina',
            contact:{}
        },
        value: 18.00,
        sales: 10,
        promotion: {
            discount: '10%',
            value: 16.00
        }
    },

    {
        id:10,
        name:'Fresas',
        description: 'Fresas',
        categories: ['hogar', 'alimentos'],
        keys: ['fresas', 'moras', 'frutas', 'frutos'],
        images: [
            require('@/assets/images/products/10/10.png')
        ],
        seller:{
            name: 'Alpina',
            description: 'Vendedor Alpina',
            contact:{}
        },
        value: 18.00,
        sales: 10,
        promotion: {
            discount: '10%',
            value: 16.00
        }
    },

    {
        id:11,
        name:'Fresas',
        description: 'Fresas',
        categories: ['hogar', 'alimentos'],
        keys: ['fresas', 'moras', 'frutas', 'frutos'],
        images: [
            require('@/assets/images/products/11/11.png')
        ],
        seller:{
            name: 'Alpina',
            description: 'Vendedor Alpina',
            contact:{}
        },
        value: 18.00,
        sales: 10,
        promotion: {
            discount: '10%',
            value: 16.00
        }
    },

    {
        id:12,
        name:'Fresas',
        description: 'Fresas',
        categories: ['hogar', 'alimentos'],
        keys: ['fresas', 'moras', 'frutas', 'frutos'],
        images: [
            require('@/assets/images/products/12/12.png')
        ],
        seller:{
            name: 'Alpina',
            description: 'Vendedor Alpina',
            contact:{}
        },
        value: 18.00,
        sales: 10,
        promotion: {
            discount: '10%',
            value: 16.00
        }
    },


    {
        id:13,
        name:'Fresas',
        description: 'Fresas',
        categories: ['hogar', 'alimentos'],
        keys: ['fresas', 'moras', 'frutas', 'frutos'],
        images: [
            require('@/assets/images/products/13/13.png')
        ],
        seller:{
            name: 'Alpina',
            description: 'Vendedor Alpina',
            contact:{}
        },
        value: 18.00,
        sales: 10,
        promotion: {
            discount: '10%',
            value: 16.00
        }
    },

    {
        id:17,
        name:'Fresas',
        description: 'Lorem ipsum dolor ut sit ame dolore adipiscing elit, sed nonumy nibh sed euismod laoreet dolore magna aliquarm erat volutpat Nostrud duis molestie at dolore. Lorem ipsum dolor ut sit ame dolore adipiscing elit, sed nonumy nibh sed euismod laoreet dolore magna aliquarm erat volutpat Nostrud duis molestie at dolore. Lorem ipsum dolor ut sit ame dolore adipiscing elit, sed nonumy nibh sed euismod laoreet dolore magna aliquarm erat volutpat Nostrud duis molestie at dolore. El sol se ocultaba tras el horizonte, tiñendo el cielo de tonos anaranjados y púrpuras. Las olas del mar golpeaban suavemente la orilla, mientras el viento susurraba entre las hojas de los árboles cercanos. Un viejo faro, erguido en lo alto de un acantilado, comenzaba a iluminar con destellos rítmicos el vasto océano. En la distancia, una barca solitaria se balanceaba al compás de las aguas, mientras su pescador observaba con calma el espectáculo del anochecer. La paz reinaba en aquel rincón del mundo.',
        categories: ['hogar', 'alimentos'],
        keys: ['fresas', 'moras', 'frutas', 'frutos'],
        images: [
            require('@/assets/images/products/17/1.avif'),
            require('@/assets/images/products/17/2.avif')
        ],
        seller:{
            name: 'Alpina',
            description: 'Vendedor Alpina',
            contact:{}
        },
        discount: '10%',
        beforeValue: '16.00',
        currentValue: '18.00',
        sales: 10,
    },
    

]


