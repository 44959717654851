<template>
<div class="row">
    <div class="col-md-12">
        <div class="product-grid row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5">
            <Product :product="product" :key="`product-${product.id}`" v-for="product of productList" />
        </div>
    </div>
</div>
</template>


<script>
import { defineComponent } from 'vue'
import Product from '@/components/Base/Product.vue'
import productList from '@/records/products.js'

export default defineComponent({
    components:{ Product },
    setup() {
        return { productList }
    },
})
</script>
