<template>
<div class="main-search p-2 rounded-3">
    <div class="main-search-button" >
        <a class="nav-link dropdown-toggle d-none d-md-block" style="font-weight: bold;" role="button" id="pages" data-bs-toggle="dropdown" aria-expanded="false">Categorias</a>
        <a class="nav-link d-block d-md-none" style="font-weight: bold;" role="button" id="pages" data-bs-toggle="dropdown" aria-expanded="false"> <i class="fas fa-chevron-down"></i> </a>
        <ul class="dropdown-menu border-0 p-3 rounded-0 shadow" aria-labelledby="pages">
            <li v-for="category of categories" :key="category">
                <router-link class="dropdown-item" :to="{'name': 'productDetails', query: {'search': category }, params: {'id': category } }" > {{ category }} </router-link>
            </li>
        </ul>
    </div>

    <div class="main-search-input">
        <div class="input-container">
            <input type="text" class="form-control border-0" placeholder="Encuentra productos, servicios y mas"  @keyup.enter="search" />
            <svg class="search-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" @click.prevent="search">
                <path d="M15.5 14h-.79l-.28-.27a6.471 6.471 0 0 0 1.48-5.34C15.18 5.04 12.14 2 8.5 2S1.82 5.04 1.82 8.5 4.86 15 8.5 15c1.61 0 3.09-.59 4.24-1.55l.27.28v.79l4.25 4.25c.39.39 1.02.39 1.41 0s.39-1.02 0-1.41l-4.25-4.25zM8.5 13c-2.48 0-4.5-2.02-4.5-4.5S6.02 4 8.5 4 13 6.02 13 8.5 10.98 13 8.5 13z"/>
            </svg>
        </div>
    </div>
</div>
</template>

<script>
import { defineComponent } from 'vue'
import categories from '@/records/categories.js'
export default defineComponent({
    name: 'InputSearch',
    setup() {
        const search = () => {
            alert('hello')
        }

        return { search , categories }
    }
})
</script>

<style scoped>
.input-container {
    position: relative;
    width: 100%;
}

.input-container input {
    width: 100%;
    padding-right: 30px; /* Espacio para el ícono */
}

.search-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    height: 25px;
    fill: #1100ff; /* Color del ícono */
    cursor:pointer; 
}

/* ============ mobile view ============ */
@media(max-width: 991px){
    .input-container input{
        font-size: 14px;
    }

    .search-icon{
        width: 20px;
        height: 20px
    }
}
</style>
