<template>
    <header class="main-header"> 
        <div class="header-container"> 
            <div class="header-logo">
                <router-link :to="{ name:'home' }">
                    <img :src="logo" alt="logo">
                </router-link>
            </div>
            
            <div class="header-search">
                <InputSearch />
            </div>

            <div style="margin-left:auto">
                <router-link :to="{ name:'home' }" class="button-rounded mx-2" style="color:white;font-size:16px">
                  <i class="fa-solid fa-user"></i>
                </router-link>
            </div>
        </div>

        <div class="second-navbar-link">
            <div class="navbar-link">
                <router-link :to="{ name:'home' }" class="button-link"> <i class="fa-solid fa-store"></i> Supermercado</router-link>
                <router-link :to="{ name:'home' }" class="button-link"> <i class="fa-solid fa-tv"></i> Electrodomesticos</router-link>
                <router-link :to="{ name:'home' }" class="button-link"> <i class="fa-solid fa-user-tie"></i> Servicios</router-link>
                <router-link :to="{ name:'home' }" class="button-link"> <i class="fa-solid fa-house"></i> Inmuebles</router-link>
            </div>
            <div class="navbar-link-right">
               <router-link :to="{ name:'home' }" class="button-link"> <i class="fa-regular fa-envelope"></i> Contactanos | Quiero vender</router-link>     
            </div>
        </div>
        
    </header>
</template>

<script>
import logo from '@/assets/logo.png';
import InputSearch from '@/components/Base/InputSearch.vue'
import { defineComponent } from 'vue';

export default defineComponent({
    components:{
        InputSearch
    },
    setup() {
        return  { logo }
    },
})
</script>

<style scoped>

.main-header{
    display:flex;
    position:fixed;
    height:100px;
    z-index: 999;
    flex-direction:column;
    width: 100%;
    box-shadow: 0 10px 10px -5px rgb(111 111 111 / 25%);
}

.second-navbar-link{
    background:#005CA9;
    display:flex;
    flex-direction:row
}
.second-navbar-link .navbar-link{
    margin-left:210px;
    display:flex;
    flex-direction:row;
}

.second-navbar-link .navbar-link-right{
    margin-left:auto;
    display:flex;
    flex-direction:row;
}

.button-rounded {
    width: 40px; 
    height: 40px;
    border-radius: 50%; 
    background-color: #D50713; 
    color: white; 
    border: none; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    font-size: 16px; 
    cursor: pointer; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    transition: all 0.3s ease; 
}

.button-rounded:hover {
    background-color: #a7070f;
}

.button-link {
    color:white;
    font-size: 0.885rem;
    font-family: var(--bs-body-font-family);
    letter-spacing: 0.0875rem;
    text-decoration: none;
    padding: 8px 40px 8px 40px;
}

.button-link:hover {
    background-color: #004e8d;
}

/* ============ mobile view ============ */
@media(max-width: 991px){
    .second-navbar-link .navbar-link {
        display: none;
    }

    .button-link {
        color:white;
        font-size: 12px;
        font-family: var(--bs-body-font-family);
        letter-spacing: 0.07rem;
        text-decoration: none;
        padding: 5px 10px;
    }
}
</style>