import { createRouter, createWebHistory } from 'vue-router'
import { loaderState } from '@/states/loaderState'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/details/:id',
    name: 'productDetails',
    component: () => import(/* webpackChunkName: "productDetails" */ '@/views/ProductDetailsView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  loaderState.isLoading = true;
  next();
});

router.afterEach((to) => {
  let delay = 250;
  if(to.name == 'productDetails') delay = 2000;
  setTimeout(() => {
    loaderState.isLoading = false;
  }, delay);
});

export default router
